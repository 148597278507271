<template>
  <div class="terms-container">
    <h1>
      <div class="text-center" style="width:100%">{{ CompanyName }} Terms & Policies</div>
    </h1>
    <p class="last-updated">Last Updated: March 24, 2025</p>

    <h2>1. Description of Services</h2>
    <p>
      {{ CompanyName }} provides unofficial practice resources to help students
      prepare for different tests. Our platform allows users to practice test
      questions and improve their skills. We do not administer the official
      tests, nor are we affiliated with or endorsed by the organizations that
      distribute these tests. Those who wish to register for the official exams
      should do so directly on their respective websites.
    </p>
    <h2>2. Purchase Currency</h2>
    <p>
      All prices on {{ CompanyName }} are displayed in <b>USD ($)</b>. Customers
      are responsible for checking exchange rates and any fees imposed by their
      payment provider when making purchases in a foreign currency.
    </p>
    <h2>3. Customer Service Contact Information</h2>
    <p>
      For any inquiries, assistance, or concerns, please contact us at
      {{ CompanyEmail }}. We strive to respond to all inquiries within 24
      business hours.
    </p>
    <h2>4. Fulfillment Policies</h2>
    <h3>4.1 Refund Policy</h3>
    <p>
      {{ CompanyName }} offers a free plan for all users to try the features
      available in our Pro and Max plans. As a result, we do not provide refunds
      once a plan has been purchased. You can view and manage your subscription
      in your Plans page.
    </p>
    <h3>4.2 Delivery Policy</h3>
    <p>
      Since our services are digital, no physical goods will be shipped.
      Immediately after a successful purchase, customers will receive access to
      the services they purchased on {{ CompanyName }}.
    </p>
    <h2>5. Legal & Export Restrictions</h2>
    <p>
      {{ CompanyName }} complies with all applicable laws and regulations. You
      are responsible for ensuring that they meet local requirements and
      eligibility criteria when you sign up for your own exam.
    </p>
    <h2>6. Privacy Policy</h2>
    <p>
      {{ CompanyName }} collects and processes user information to provide and
      improve our services. This includes account details, payment information,
      and usage data. We may use third-party services, such as Stripe for
      payments and Google Analytics for tracking, which have their own privacy
      policies. Our website also uses cookies to enhance functionality, analyze
      site traffic, and improve user experience; users can manage cookie
      preferences in their browser settings. We prioritize data security and
      implement measures to protect your personal information from unauthorized
      access or misuse. {{ CompanyName }} does not sell, trade, or rent user
      data, and we only share information when required by law or when necessary
      for service providers to fulfill their obligations. By using
      {{ CompanyName }}, you agree to this Privacy Policy, which may be updated
      from time to time.
    </p>
    <h2>7. Promotions & Discounts</h2>
    <p>
      Any promotional offers or discounts will be explicitly stated on our
      website, including terms and conditions regarding eligibility, duration,
      and redemption process.
    </p>
    <h2>8. Website Security & Payment Information</h2>
    <p>
      We prioritize the security of your personal and payment information.
      {{ CompanyName }} complies with PCI DSS (Payment Card Industry Data
      Security Standard) and uses SSL encryption (HTTPS) to protect
      transactions. Your payment details are securely processed through our
      payment provider, and we do not store any credit card information on our
      servers.
    </p>
    <h2>9. Accepted Payment Methods</h2>
    <p>
      We accept the following payment methods: Visa, Mastercard, American
      Express.
    </p>
    <p class="footer-note">
      We accept the following payment methods: Visa, Mastercard, American
      Express. By using {{ CompanyName }}, you agree to these terms. For any
      questions, feel free to contact us at
      <a :href="`mailto:${CompanyEmail}`">{{ CompanyEmail }}.</a>
    </p>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */

export default {
  metaInfo() {
    return {
      title: "Terms - " + this.CompanyName
    };
  },

  components: {},

  mixins: [],

  props: [],
  data() {
    return {
      sections: []
    };
  },
  computed: {},
  watch: {},

  mounted() {},

  methods: {}
};
</script>

<style scoped>
.terms-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 20px;
  line-height: 1.8;
  color: #4a4a4a;
}

h1 {
  color: #1a1a1a;
  text-align: center;
  margin-bottom: 15px;
  font-size: 2.5em;
  font-weight: 600;
}

.last-updated {
  text-align: center;
  color: #888;
  margin-bottom: 50px;
  font-size: 0.9em;
}

h2 {
  color: #1a1a1a;
  margin: 35px 0 20px;
  font-size: 1.8em;
  font-weight: 500;
  border-bottom: 2px solid #f0f0f0;
  padding-bottom: 10px;
}

h3 {
  color: #2a2a2a;
  margin: 25px 0 15px;
  font-size: 1.4em;
  font-weight: 500;
}

p {
  margin-bottom: 1.2em;
}

a {
  color: #2196f3;
  text-decoration: none;
  transition: color 0.2s ease;
}

a:hover {
  color: #1976d2;
  text-decoration: none;
}

.footer-note {
  margin-top: 50px;
  padding-top: 25px;
  border-top: 2px solid #f0f0f0;
  color: #888;
  font-size: 0.95em;
  text-align: center;
}

@media (max-width: 768px) {
  .terms-container {
    padding: 20px 15px;
  }

  h1 {
    font-size: 2em;
  }

  h2 {
    font-size: 1.5em;
  }

  h3 {
    font-size: 1.2em;
  }
}
</style>
